@charset "UTF-8";
.wizardTitleCont {
  margin-top: 70px;
}

.EthToSysWizard,
.SysToEthWizard,
.SysToSysxWizard,
.SysxToSysWizard {
  max-width: 1000px;
  margin: 0 auto;
  background: rgba(113, 229, 255, 0.14);
  position: relative;
  color: #dddfe8;
  -webkit-box-shadow: 0 14px 32px 0 rgba(2, 92, 221, 0.3), 0 16px 32px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 14px 32px 0 rgba(2, 92, 221, 0.3), 0 16px 32px 0 rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}
.EthToSysWizard input.form-control,
.EthToSysWizard textarea.form-control,
.SysToEthWizard input.form-control,
.SysToEthWizard textarea.form-control,
.SysToSysxWizard input.form-control,
.SysToSysxWizard textarea.form-control,
.SysxToSysWizard input.form-control,
.SysxToSysWizard textarea.form-control {
  color: #dedede;
  background-color: #1f1c5e85;
  background-image: none;
  border: 0px solid #232e73;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}
.EthToSysWizard input.form-control::focus,
.EthToSysWizard textarea.form-control::focus,
.SysToEthWizard input.form-control::focus,
.SysToEthWizard textarea.form-control::focus,
.SysToSysxWizard input.form-control::focus,
.SysToSysxWizard textarea.form-control::focus,
.SysxToSysWizard input.form-control::focus,
.SysxToSysWizard textarea.form-control::focus {
  background: #232e73ad;
}
.EthToSysWizard label,
.SysToEthWizard label,
.SysToSysxWizard label,
.SysxToSysWizard label {
  color: #d3dfff;
}
.EthToSysWizard label h1,
.SysToEthWizard label h1,
.SysToSysxWizard label h1,
.SysxToSysWizard label h1 {
  text-align: center;
}
.EthToSysWizard .control-label,
.SysToEthWizard .control-label,
.SysToSysxWizard .control-label,
.SysxToSysWizard .control-label {
  font-size: 16px;
  font-weight: 700;
  /* margin-bottom: 9px; */
  color: #d3dfff;
  padding: 7px 15px 2px 12px;
  background: #1f1c5e85;
  margin-top: 20px;
  margin-bottom: 0;
  width: auto;
}
.EthToSysWizard input[type=number]::-webkit-inner-spin-button,
.EthToSysWizard input[type=number]::-webkit-outer-spin-button,
.SysToEthWizard input[type=number]::-webkit-inner-spin-button,
.SysToEthWizard input[type=number]::-webkit-outer-spin-button,
.SysToSysxWizard input[type=number]::-webkit-inner-spin-button,
.SysToSysxWizard input[type=number]::-webkit-outer-spin-button,
.SysxToSysWizard input[type=number]::-webkit-inner-spin-button,
.SysxToSysWizard input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.EthToSysWizard .val-err-tooltip,
.SysToEthWizard .val-err-tooltip,
.SysToSysxWizard .val-err-tooltip,
.SysxToSysWizard .val-err-tooltip {
  background-color: #981a1a75;
}
.EthToSysWizard .has-success input.form-control,
.EthToSysWizard .has-success textarea.form-control, .EthToSysWizard .has-error input.form-control,
.EthToSysWizard .has-error textarea.form-control, .EthToSysWizard .no-error input.form-control,
.EthToSysWizard .no-error textarea.form-control,
.SysToEthWizard .has-success input.form-control,
.SysToEthWizard .has-success textarea.form-control,
.SysToEthWizard .has-error input.form-control,
.SysToEthWizard .has-error textarea.form-control,
.SysToEthWizard .no-error input.form-control,
.SysToEthWizard .no-error textarea.form-control,
.SysToSysxWizard .has-success input.form-control,
.SysToSysxWizard .has-success textarea.form-control,
.SysToSysxWizard .has-error input.form-control,
.SysToSysxWizard .has-error textarea.form-control,
.SysToSysxWizard .no-error input.form-control,
.SysToSysxWizard .no-error textarea.form-control,
.SysxToSysWizard .has-success input.form-control,
.SysxToSysWizard .has-success textarea.form-control,
.SysxToSysWizard .has-error input.form-control,
.SysxToSysWizard .has-error textarea.form-control,
.SysxToSysWizard .no-error input.form-control,
.SysxToSysWizard .no-error textarea.form-control {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.EthToSysWizard .has-success .val-success-tooltip, .EthToSysWizard .has-error .val-success-tooltip, .EthToSysWizard .no-error .val-success-tooltip,
.SysToEthWizard .has-success .val-success-tooltip,
.SysToEthWizard .has-error .val-success-tooltip,
.SysToEthWizard .no-error .val-success-tooltip,
.SysToSysxWizard .has-success .val-success-tooltip,
.SysToSysxWizard .has-error .val-success-tooltip,
.SysToSysxWizard .no-error .val-success-tooltip,
.SysxToSysWizard .has-success .val-success-tooltip,
.SysxToSysWizard .has-error .val-success-tooltip,
.SysxToSysWizard .no-error .val-success-tooltip {
  padding: 0;
}
.EthToSysWizard .has-success .val-success-tooltip.active, .EthToSysWizard .has-error .val-success-tooltip.active, .EthToSysWizard .no-error .val-success-tooltip.active,
.SysToEthWizard .has-success .val-success-tooltip.active,
.SysToEthWizard .has-error .val-success-tooltip.active,
.SysToEthWizard .no-error .val-success-tooltip.active,
.SysToSysxWizard .has-success .val-success-tooltip.active,
.SysToSysxWizard .has-error .val-success-tooltip.active,
.SysToSysxWizard .no-error .val-success-tooltip.active,
.SysxToSysWizard .has-success .val-success-tooltip.active,
.SysxToSysWizard .has-error .val-success-tooltip.active,
.SysxToSysWizard .no-error .val-success-tooltip.active {
  padding: 3px 5px 3px 10px;
}
.EthToSysWizard .formbtn,
.SysToEthWizard .formbtn,
.SysToSysxWizard .formbtn,
.SysxToSysWizard .formbtn {
  margin: 30px auto 30px;
  background: #359ef6;
  text-shadow: none;
  color: white;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  font-size: 16px;
  line-height: 28px;
  height: 40px;
  border: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.EthToSysWizard .formbtn:hover, .EthToSysWizard .formbtn:active, .EthToSysWizard .formbtn:focus,
.SysToEthWizard .formbtn:hover,
.SysToEthWizard .formbtn:active,
.SysToEthWizard .formbtn:focus,
.SysToSysxWizard .formbtn:hover,
.SysToSysxWizard .formbtn:active,
.SysToSysxWizard .formbtn:focus,
.SysxToSysWizard .formbtn:hover,
.SysxToSysWizard .formbtn:active,
.SysxToSysWizard .formbtn:focus {
  background: #252c6c;
  color: white;
}
.EthToSysWizard .formbtnmini,
.SysToEthWizard .formbtnmini,
.SysToSysxWizard .formbtnmini,
.SysxToSysWizard .formbtnmini {
  background: #359ef6;
  text-shadow: none;
  color: white;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  font-size: 16px;
  border: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.EthToSysWizard .formbtnmini:hover, .EthToSysWizard .formbtnmini:active, .EthToSysWizard .formbtnmini:focus,
.SysToEthWizard .formbtnmini:hover,
.SysToEthWizard .formbtnmini:active,
.SysToEthWizard .formbtnmini:focus,
.SysToSysxWizard .formbtnmini:hover,
.SysToSysxWizard .formbtnmini:active,
.SysToSysxWizard .formbtnmini:focus,
.SysxToSysWizard .formbtnmini:hover,
.SysxToSysWizard .formbtnmini:active,
.SysxToSysWizard .formbtnmini:focus {
  background: #252c6c;
  color: white;
}
.EthToSysWizard code,
.SysToEthWizard code,
.SysToSysxWizard code,
.SysxToSysWizard code {
  line-height: 1em;
  padding: 3px;
  background-color: #d3dfff;
  left: 0;
}
.EthToSysWizard code .result,
.SysToEthWizard code .result,
.SysToSysxWizard code .result,
.SysxToSysWizard code .result {
  color: #1d5f96;
  display: inline-block;
  float: left;
}
.EthToSysWizard code .dataname,
.SysToEthWizard code .dataname,
.SysToSysxWizard code .dataname,
.SysxToSysWizard code .dataname {
  width: 280px;
  display: inline-block;
  float: left;
  clear: both;
}
.EthToSysWizard code.block,
.SysToEthWizard code.block,
.SysToSysxWizard code.block,
.SysxToSysWizard code.block {
  display: block;
  padding: 6px;
  line-height: 1.2em;
  overflow: hidden;
  word-break: break-all;
}
.EthToSysWizard code.blue,
.SysToEthWizard code.blue,
.SysToSysxWizard code.blue,
.SysxToSysWizard code.blue {
  color: #1d5f96;
}
.EthToSysWizard em.step,
.SysToEthWizard em.step,
.SysToSysxWizard em.step,
.SysxToSysWizard em.step {
  color: #359ef6;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  text-transform: uppercase;
}
.EthToSysWizard .btn-default.disabled, .EthToSysWizard .btn-default.disabled.active, .EthToSysWizard .btn-default.disabled.focus, .EthToSysWizard .btn-default.disabled:active, .EthToSysWizard .btn-default.disabled:focus, .EthToSysWizard .btn-default.disabled:hover, .EthToSysWizard .btn-default[disabled], .EthToSysWizard .btn-default[disabled].active, .EthToSysWizard .btn-default[disabled].focus, .EthToSysWizard .btn-default[disabled]:active, .EthToSysWizard .btn-default[disabled]:focus, .EthToSysWizard .btn-default[disabled]:hover, .EthToSysWizard fieldset[disabled] .btn-default, .EthToSysWizard fieldset[disabled] .btn-default.active, .EthToSysWizard fieldset[disabled] .btn-default.focus, .EthToSysWizard fieldset[disabled] .btn-default:active, .EthToSysWizard fieldset[disabled] .btn-default:focus, .EthToSysWizard fieldset[disabled] .btn-default:hover,
.SysToEthWizard .btn-default.disabled,
.SysToEthWizard .btn-default.disabled.active,
.SysToEthWizard .btn-default.disabled.focus,
.SysToEthWizard .btn-default.disabled:active,
.SysToEthWizard .btn-default.disabled:focus,
.SysToEthWizard .btn-default.disabled:hover,
.SysToEthWizard .btn-default[disabled],
.SysToEthWizard .btn-default[disabled].active,
.SysToEthWizard .btn-default[disabled].focus,
.SysToEthWizard .btn-default[disabled]:active,
.SysToEthWizard .btn-default[disabled]:focus,
.SysToEthWizard .btn-default[disabled]:hover,
.SysToEthWizard fieldset[disabled] .btn-default,
.SysToEthWizard fieldset[disabled] .btn-default.active,
.SysToEthWizard fieldset[disabled] .btn-default.focus,
.SysToEthWizard fieldset[disabled] .btn-default:active,
.SysToEthWizard fieldset[disabled] .btn-default:focus,
.SysToEthWizard fieldset[disabled] .btn-default:hover,
.SysToSysxWizard .btn-default.disabled,
.SysToSysxWizard .btn-default.disabled.active,
.SysToSysxWizard .btn-default.disabled.focus,
.SysToSysxWizard .btn-default.disabled:active,
.SysToSysxWizard .btn-default.disabled:focus,
.SysToSysxWizard .btn-default.disabled:hover,
.SysToSysxWizard .btn-default[disabled],
.SysToSysxWizard .btn-default[disabled].active,
.SysToSysxWizard .btn-default[disabled].focus,
.SysToSysxWizard .btn-default[disabled]:active,
.SysToSysxWizard .btn-default[disabled]:focus,
.SysToSysxWizard .btn-default[disabled]:hover,
.SysToSysxWizard fieldset[disabled] .btn-default,
.SysToSysxWizard fieldset[disabled] .btn-default.active,
.SysToSysxWizard fieldset[disabled] .btn-default.focus,
.SysToSysxWizard fieldset[disabled] .btn-default:active,
.SysToSysxWizard fieldset[disabled] .btn-default:focus,
.SysToSysxWizard fieldset[disabled] .btn-default:hover,
.SysxToSysWizard .btn-default.disabled,
.SysxToSysWizard .btn-default.disabled.active,
.SysxToSysWizard .btn-default.disabled.focus,
.SysxToSysWizard .btn-default.disabled:active,
.SysxToSysWizard .btn-default.disabled:focus,
.SysxToSysWizard .btn-default.disabled:hover,
.SysxToSysWizard .btn-default[disabled],
.SysxToSysWizard .btn-default[disabled].active,
.SysxToSysWizard .btn-default[disabled].focus,
.SysxToSysWizard .btn-default[disabled]:active,
.SysxToSysWizard .btn-default[disabled]:focus,
.SysxToSysWizard .btn-default[disabled]:hover,
.SysxToSysWizard fieldset[disabled] .btn-default,
.SysxToSysWizard fieldset[disabled] .btn-default.active,
.SysxToSysWizard fieldset[disabled] .btn-default.focus,
.SysxToSysWizard fieldset[disabled] .btn-default:active,
.SysxToSysWizard fieldset[disabled] .btn-default:focus,
.SysxToSysWizard fieldset[disabled] .btn-default:hover {
  background-color: #6f6f6f;
  background-image: none;
}
.EthToSysWizard a.vivid,
.SysToEthWizard a.vivid,
.SysToSysxWizard a.vivid,
.SysxToSysWizard a.vivid {
  color: #88b2d6;
  background: #1f1c5e;
  padding: 3px 6px;
  margin-top: 5px;
  display: inline-block;
  text-decoration: none;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  -webkit-box-shadow: 0 14px 32px 0 rgba(2, 92, 221, 0.3), 0 16px 32px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 14px 32px 0 rgba(2, 92, 221, 0.3), 0 16px 32px 0 rgba(0, 0, 0, 0.12);
}
.EthToSysWizard a.vivid:hover,
.SysToEthWizard a.vivid:hover,
.SysToSysxWizard a.vivid:hover,
.SysxToSysWizard a.vivid:hover {
  color: white;
}
.EthToSysWizard .mb30,
.SysToEthWizard .mb30,
.SysToSysxWizard .mb30,
.SysxToSysWizard .mb30 {
  margin-bottom: 30px;
}

.react-tabs__tab-list {
  margin-bottom: 0;
  margin-left: 10px;
  border: 0;
}
.react-tabs__tab-list .react-tabs__tab {
  border: 0;
}
.react-tabs__tab-list .react-tabs__tab--selected {
  background: #d3dfff;
}

.col-centered {
  float: none !important;
  margin: 0 auto;
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 750px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 750px) {
  .progtrckr em {
    display: inline;
  }
}
@media (max-width: 450px) {
  ol.progtrckr {
    padding-top: 40px;
  }
}
@media (max-width: 600px) {
  code .result {
    color: #1d5f96;
    display: inline-block;
    float: left;
    clear: both;
  }
  code .dataname {
    display: inline-block;
    float: left;
    clear: both;
  }
}
ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: #359ef6;
  border-bottom: 4px solid #359ef6;
}

ol.progtrckr li.progtrckr-done {
  color: white;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: "  ";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "•";
  color: silver;
  background-color: white;
  width: 1.3em;
  line-height: 1.3em;
  border-radius: 1.3em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #359ef6;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "•";
  color: #202f64;
  background-color: #359ef6;
  width: 1.3em;
  line-height: 1.3em;
  border-radius: 1.3em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #fff;
}

ol.progtrckr li.progtrckr-done:before {
  content: "✓";
  color: white;
  background-color: #5cb85c;
  width: 1.3em;
  line-height: 1.3em;
  border-radius: 1.3em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.relaypagecontainer {
  display: inline-flex;
  margin-top: 20px;
}

.relaypageicon {
  cursor: pointer;
}

.relaypage {
  color: #359ef6;
  margin-top: 7px;
  font-size: 24px;
  font-weight: bolder;
}

.relaysearcherror {
  color: #ff4500;
  font-size: 16px;
  font-weight: bolder;
}

.add-on .input-group-btn > .btn {
  border-left-width: 0;
  left: -2px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

/* stop the glowing blue shadow */
.add-on .form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #cccccc;
}

.add-on {
  width: 100%;
}

.val-success-tooltip {
  background-color: green;
  padding: 3px 5px 3px 10px;
  font-size: 14px;
  color: #fff;
}

@media (max-width: 835px) {
  .wizardTitleCont {
    margin-top: 105px;
  }
}